import { useCallback, useEffect, useState } from "react";
import { asText } from "@prismicio/helpers";
import Cookies from "js-cookie";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "@wbly/ui";
import { type SwitchCountrySlice } from "@wbly/data-storefront";
import { Close } from "../Header/Icons";
import styles from "./SwitchCountry.module.css";
import {
  trackCloseSwitchCountry,
  trackSwitchCountryVisible,
  trackCountryNavigation,
  trackStayInCountry,
} from "./track-switch-country";

type SwitchCountryProps = {
  marketplace: string;
} & SwitchCountrySlice;

const {
  container,
  containerClosing,
  modalTitle,
  modalText,
  overlay,
  closeButton,
  usCtaButton,
  switchCtaButton,
} = styles;

export function SwitchCountry({
  title,
  content,
  usCtaTitle,
  switchCtaTitle,
  marketplace,
}: SwitchCountryProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    const checkAndOpenDialog = async () => {
      try {
        // Check for persistent cookie and session override
        const persistentOverride = Cookies.get("locale_user_override_always");
        const sessionOverride = sessionStorage.getItem(
          "locale_user_override_session"
        );

        // If either is set, do not open the modal
        if (persistentOverride || sessionOverride) {
          return;
        }

        // Check if user is on the homepage
        if (window.location.pathname === "/") {
          const response = await fetch("/geo");
          const geoData = await response.json();

          // Open modal if the marketplace is US and the user is not in the US
          if (marketplace === "us" && !geoData.isUS) {
            sessionStorage.setItem("locale_user_override_session", "true"); // Set session override
            setOpenDialog(true);
            trackSwitchCountryVisible();
          }
        }
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
      }
    };

    checkAndOpenDialog();
  }, [marketplace]);

  const handleClose = useCallback(() => {
    setClosing(true);
    setTimeout(() => setOpenDialog(false), 300); // Close after animation
    trackCloseSwitchCountry();
  }, []);

  const handleNavigateToCountry = useCallback(() => {
    window.location.href = "/choose-your-country";
    trackCountryNavigation();
  }, []);

  const handleStayInCountry = useCallback(() => {
    // Set persistent override cookie
    Cookies.set("locale_user_override_always", "true", { expires: 60 });
    trackStayInCountry();
    // I'm not using handleClose directly here to avoid
    // re-triggering the trackCloseSwitchCountry event
    setClosing(true);
    setTimeout(() => setOpenDialog(false), 300);
  }, []);

  if (!openDialog) {
    return null;
  }

  return (
    <Dialog.Root open={openDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className={overlay} />
        <Dialog.Content
          className={`${container} ${closing ? containerClosing : ""}`}
        >
          <Dialog.Close asChild>
            <Button
              className={closeButton}
              aria-label="close modal"
              onClick={handleClose}
            >
              <Close />
            </Button>
          </Dialog.Close>
          <h1 className={modalTitle}>{title}</h1>
          <p className={modalText}>{asText(content)}</p>
          <Button
            onClick={handleNavigateToCountry}
            outline={true}
            fullWidth={true}
            variant="secondary"
            className={switchCtaButton}
          >
            {asText(switchCtaTitle)}
          </Button>
          <Button
            onClick={handleStayInCountry}
            fullWidth={true}
            variant="secondary"
            className={usCtaButton}
          >
            {asText(usCtaTitle)}
          </Button>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
